






import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class MandateFail extends Vue {
  public name = "MandateFail";

  public failMessage = "Mandate import failed";
}
